import React from 'react'

const NotFount_404 = () => {
    return (
      <div className='' style={{ minHeight:331, minWidth:'100%' }}>
            <h4 style={{ marginTop:145, marginLeft:10, }} className='text-light'>Page not found</h4>
      </div>
    )
};

export default NotFount_404;
